// Not totally type-safe if something in localStorage doesn't match its type on read
const get = <T = any>(key: string): T | null => {
  if (!isLocalStorageAvailable()) return null

  try {
    const value = window.localStorage.getItem(key)
    return value as unknown as T
  } catch (err) {
    console.error(err)
    return null
  }
}

// Not totally typesafe if something in localStorage doesn't match its type on read
const getJSON = <T = any>(key: string): T | null => {
  if (!isLocalStorageAvailable()) return null

  try {
    const value = window.localStorage.getItem(key)
    if (!value) return null
    return JSON.parse(value) as T
  } catch (err) {
    console.error(err)
    return null
  }
}

const isLocalStorageAvailable = (): boolean => {
  try {
    return typeof window !== 'undefined' && typeof window.localStorage !== 'undefined'
  } catch {
    return false
  }
}

const remove = (key: string): void => {
  if (!isLocalStorageAvailable()) return

  try {
    window.localStorage.removeItem(key)
  } catch (err) {
    console.error(err)
  }
}

const set = (key: string, value: string): void => {
  if (!isLocalStorageAvailable()) return

  try {
    window.localStorage.setItem(key, value)
  } catch (err) {
    console.error(err)
  }
}

const setJSON = (key: string, obj: any): void => {
  if (!isLocalStorageAvailable()) return

  try {
    const value = JSON.stringify(obj)
    window.localStorage.setItem(key, value)
  } catch (err) {
    console.error(err)
  }
}

const localStorageService = {
  get,
  getJSON,
  remove,
  set,
  setJSON,
}

export default localStorageService

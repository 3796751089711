import Button from 'react-bootstrap/Button'

import saltBlack from '@/assets/salt_48.png'
import { useTheme } from '@/components/ThemeProvider/ThemeProvider'

import styles from './SaltVoteButton.module.scss'

type Props = {
  salt: number
  setVoteLoading: (flag: boolean, vote: number) => void
  vote: string
  voted: boolean
}

const VoteButton: React.FC<Props> = ({ salt, setVoteLoading, vote, voted }: Props) => {
  const [theme] = useTheme()

  const submitVote = async () => {
    setVoteLoading(true, salt)
    await fetch('https://us-central1-edhrec-salt.cloudfunctions.net/api/vote', {
      body: JSON.stringify({ salt, vote }),
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
    }).catch((e) => console.error(e))
    setVoteLoading(false, salt)
  }

  return (
    <Button
      aria-label={`Vote ${salt} salt`}
      className={styles.button}
      disabled={voted}
      onClick={submitVote}
      variant={`outline-${theme === 'dark' ? 'light' : 'dark'}`}
    >
      {salt === 0
        ? 'Not at all'
        : [...Array(salt)].map((_value: undefined, index: number) => (
            <img alt='Salt Logo' key={index} src={saltBlack.src} width={16} />
          ))}
    </Button>
  )
}

export default VoteButton

import { SaltCardType } from './SaltBody'
import styles from './SaltPreviousCardInfo.module.scss'

type Props = {
  card: SaltCardType
  vote: number
}

const PreviousCardInfo = ({ card, vote }: Props) => (
  <div className={styles.container}>
    <div>
      <span className={styles.bold}>Previous card:</span> {card.name}
    </div>
    <div>
      <span className={styles.bold}>Average salt score:</span>{' '}
      <span>{card.currentSaltScore ? card.currentSaltScore.toFixed(2) : 0}</span>
    </div>
    <div>
      <span className={styles.bold}>Your salt vote:</span> {vote}
    </div>
  </div>
)

export default PreviousCardInfo

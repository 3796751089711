import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'

import Icon from '@/components/Icon/Icon'

import styles from './SaltVoteCount.module.scss'

const MILESTONES = {
  // eslint-disable-next-line sort/object-properties
  Beginner: {
    name: 'Beginner',
    next: '50',
  },
  '50': {
    name: '50',
    next: '100',
  },
  '100': {
    name: '100',
    next: '200',
  },
  '200': {
    name: '200',
    next: '500',
  },
  '500': {
    name: '500',
    next: '1000',
  },
  '1000': {
    name: '1000',
    next: '2000',
  },
  '2000': {
    name: '2000',
    next: '5000',
  },
  '5000': {
    name: '5000',
    next: '10000',
  },
  '10000': {
    name: '10000',
    next: 'Take a break',
  },
}

const milestoneMapper = (count: number) => {
  if (count < 50) return MILESTONES['Beginner']
  else if (count < 100) return MILESTONES['50']
  else if (count < 200) return MILESTONES['100']
  else if (count < 500) return MILESTONES['200']
  else if (count < 1000) return MILESTONES['500']
  else if (count < 2000) return MILESTONES['1000']
  else if (count < 5000) return MILESTONES['2000']
  else if (count < 10000) return MILESTONES['5000']
  return MILESTONES['10000']
}

type Props = {
  voteCount: number
}

const usePrevious = <T,>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const VoteCount: React.FC<Props> = ({ voteCount }) => {
  const prevVoteCount = usePrevious(voteCount)

  useEffect(() => {
    if (prevVoteCount && milestoneMapper(prevVoteCount) !== milestoneMapper(voteCount)) {
      setMilestoneAchieved(true)
      setTimeout(() => setMilestoneAchieved(false), 7000)
    }
  }, [voteCount, prevVoteCount])

  const [milestoneAchieved, setMilestoneAchieved] = useState(false)

  const milestone = milestoneMapper(voteCount)

  return (
    <a
      href={`https://bsky.app/intent/compose?text=I've submitted ${voteCount} votes to the EDH Salt Score project. https://edhrec.com/salt`}
      rel='noopener noreferrer'
      target='_blank'
    >
      <div className={classNames(styles.container, { [styles.milestone]: milestoneAchieved })}>
        <div className='d-flex flex-column justify-content-center me-3'>
          <div>Your votes: {voteCount}</div>
          <div>Milestone: {milestone.name}</div>
          <div>Next milestone: {milestone.next}</div>
        </div>
        <Icon icon='bluesky' />
      </div>
    </a>
  )
}

export default VoteCount
